<template>
  <div>
    <div class="row">
        <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 offset-sm-2 offset-md-2 offset-lg-2 p-10">
            <div class="row p-10 bgColorResume">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <img src="media/images/openpay/trofeo.png" alt="">
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center mt-2">
                <h1 class="fw-bold fs-xl">Tu vehículo fue apartado exitosamente</h1>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center mt-2">
                <p class="fw-bold" style="font-size: 18px;">Nos comunicaremos contigo para continuar el proceso</p>
              </div>
              <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 offset-sm-1 offset-md-1 offset-lg-1">
                <CardVehicle :vehicle="vehicleToSend" />
              </div>
              <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 pe-0">
                <div ref="mapDiv" class="mt-3 rounded" style="width: 100%; height: 220px"></div>
                <div class="row">
                  <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 pt-4">
                    <img src="media/images/brands/Toyota.svg" alt="" style="width: 50px; padding: 13px 5px;
                      background-color: #393840; border-radius: 5px;">
                  </div>
                  <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10 pt-5">
                    <p style="font-size: 14px; margin-bottom: 0px;">{{nombreAgencia}}</p>
                    <span style="font-size: 14px; color: #939598;">{{direccionAgencia}}</span>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 offset-sm-3 offset-md-3 offset-lg-3 text-center ">
                  <button @click="goToWhats" class="btn btn-success px-15">
                    <inline-svg src="media/icons/iconsax/Whatsapp.svg"></inline-svg>&nbsp;&nbsp;Contactar por WhatsApp
                  </button>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { onMounted, ref, watch } from "vue";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import CardVehicle from '../components/CardVehicle.vue';
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import {Loader} from  '@googlemaps/js-api-loader';
const API_KEY="AIzaSyACDXm5WBEngJZHLoEiSzzYCdlkg3THQmI";

export default {
  name: "ResumenApartado",
  components: {
    CardVehicle
  },
  setup() {

    const variable = ref('');
    const vehicles = ref([]);
    const nombreAgencia = ref("");
    const direccionAgencia = ref("");
    const whatsAgencia = ref("");

    const priceFormat = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const kmFormat = (value) => {
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const vehicle = ref({});
    const vehicleToSend = ref({});
    const route = useRoute();

    watch(()=> route.params.id, async()=>{
      await getDetail(route.params.id);
      vehicles.value = [];
    })

    onMounted(async () => {
      vehicleToSend.value.id = route.params.id;
      await getDetail(route.params.id);
    });

    const mapDiv = ref(null);
    const loader = new Loader({apiKey: API_KEY});
    const map = ref();
    const images = ref([]);
    const images_d = ref([]);

    const getDetail = async (id) => {
      try {
        const { data } = await ApiService.get("/api/vehicle/" + id);
        vehicle.value = data.data;
        nombreAgencia.value = vehicle.value.agency.name;
        direccionAgencia.value = vehicle.value.agency.address;
        whatsAgencia.value = vehicle.value.agency.url_whatsapp;
        vehicleToSend.value.status = vehicle.value.status.status;
        await loader.load();
        variable.value = vehicle.value.agency.phone
        images.value = vehicle.value.images.filter(i => i.type == 'Vehiculo');
        vehicleToSend.value.images = images.value;
        vehicleToSend.value.brand = vehicle.value.brand.brand;
        vehicleToSend.value.model = vehicle.value.model.model;
        vehicleToSend.value.version = vehicle.value.version.version;
        vehicleToSend.value.year = vehicle.value.year.year;
        vehicleToSend.value.Mileage = vehicle.value.mileage;
        vehicleToSend.value.Estado = vehicle.value.agency.state;
        vehicleToSend.value.Price = vehicle.value.price;
        vehicleToSend.value.slug = vehicle.value.slug;
        images_d.value = vehicle.value.images.filter(i => i.type == 'Desperfecto');
        if(vehicle.value.agency.latitude && vehicle.value.agency.longitude){
          map.value  = new google.maps.Map(mapDiv.value, {
            center: {lat: parseFloat(vehicle.value.agency.latitude), lng:parseFloat(vehicle.value.agency.longitude)},
            zoom: 13
          })
          new google.maps.Marker({
            position: {lat: parseFloat(vehicle.value.agency.latitude), lng: parseFloat(vehicle.value.agency.longitude)},
            map: map.value,
            title: vehicle.value.agency.name,
          });
        }

      } catch (error) {
        console.log(error);
      }
    };

    const goToWhats = () => {
      window.location.href = whatsAgencia.value;
    }
    
    return {
      modules: [Navigation],
      images,
      vehicle,
      priceFormat,
      kmFormat,
      vehicles,
      mapDiv,
      images_d,
      variable,
      vehicleToSend,
      nombreAgencia,
      direccionAgencia,
      whatsAgencia,
      goToWhats
    };
  },
};
</script>
<style lang="scss" scoped>
.bgColorResume {
    background-color: #272b31;
    border-radius: 10px;
}
</style>
