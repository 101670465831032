
import GalleryCard from "./GalleryCard.vue";
import { kmFormat } from "../../../helpers/globalFuntions";
export default {
  name: "card",
  components: {
    GalleryCard,
  },
  props: {
    vehicle: Object,
    typeCard: {
      type: String,
      default: "cards",
    },
  },
  setup(props) {
    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    return {
      formatPrice,
      kmFormat,
    };
  },
};
